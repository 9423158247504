.swiper{
    width: 100%;
    height: 100%;
}

.slide{
    width:100% ;
    height: 100%;
    object-fit: cover;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  margin-left: 5px;
  margin-right: 5px;
  color: #ffffff;
}

.swiper-pagination-bullet {
  background-color: #ddd !important; 
}

.swiper-pagination-bullet-active {
  background-color: red !important;
}